//___________COLORS____________

$dark: #121b2d;
$darkBg: #1f2638;
$blue: #2b63d3;
$green: #4daf00;
$orange: #f79f44;
$grey: #a3b2bd;
$grey2: #ccc;
$lightBorder: #eef2f5;
$red: #ed2709;
$brown: #926522;
$yellow: #f9c206;

//___________VARIABLES___________

$gradGreen: linear-gradient(174deg, #399a48 0%, #aacc3a 100%);
$gradGreenDark: linear-gradient(133deg, #356b3e 0%, #aacc3a 100%);
$gradOrange: linear-gradient(133deg, #f8b042 0%, #f79f44 100%);

//___________FONTS____________

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Medium.eot');
  src: local('Gilroy Medium'), local('Gilroy-Medium'), url('fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/Gilroy-Medium.woff2') format('woff2'), url('fonts/Gilroy-Medium.woff') format('woff'),
    url('fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Semibold.eot');
  src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
    url('fonts/Gilroy-Semibold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Gilroy-Semibold.woff2') format('woff2'), url('fonts/Gilroy-Semibold.woff') format('woff'),
    url('fonts/Gilroy-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Bold.eot');
  src: local('Gilroy Bold'), local('Gilroy-Bold'), url('fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Gilroy-Bold.woff2') format('woff2'), url('fonts/Gilroy-Bold.woff') format('woff'),
    url('fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

//___________MIXINS____________

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin centerInline {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin trans {
  transition: 0.6s all;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absoluteCenterX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absoluteCenterY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin fullImg {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

@mixin hoverBtn {
  padding: 0.1rem 0.2rem;
  transition: none;

  &:hover {
    background: rgba($color: #ddd, $alpha: 0.6);
    border-radius: 0.2rem;
  }

  &:active {
    background: rgba($color: #ddd, $alpha: 0.3);
    border-radius: 0.2rem;
    transition: none;
  }
}

.novas {
  svg {
    transition: .3s all;
    path {
      transition: .3s;
    }
  }
}

//___________COMMOM____________

:root {
  font-size: 30px;
}

ul,
li {
  margin: 0;
  padding: 0;
  display: block;
}

a {
  transition: 0.3s all;
  outline: none;
  text-decoration: none;
  color: $blue;

  &:hover {
    text-decoration: none;
    color: #0038ff;
  }

  &:active {
    background: rgba($color: #ddd, $alpha: 0.5);
  }
}

button {
  @include trans();
  border: none;
  outline: none;
  background: none;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

input,
select,
option,
textarea {
  outline: none;
  background: #fff;
  border: 1px solid $grey2;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;

  &::placeholder {
    color: #e1e1e1;
  }

  &:focus {
    border-color: $green;
  }
}

label {
  user-select: none;
}

.right-to-left-enter {
  transform: translateX(100%);
}
.right-to-left-enter-active {
  transform: translateX(0);
  transition: all 1s ease;
}

.right-to-left-exit {
  transform: translateX(0);
}
.right-to-left-exit-active {
  transform: translateX(-100%);
  transition: all 1s ease;
}

.left-to-right-enter {
  transform: translateX(-100%);
}
.left-to-right-enter-active {
  transform: translateX(0);
  transition: all 1s ease;
}

.left-to-right-exit {
  transform: translateX(0);
}
.left-to-right-exit-active {
  transform: translateX(100%);
  transition: all 1s ease;
}

@keyframes fillBorder {
  to {
    width: 100%;
  }
}

body {
  font-family: 'Gilroy';
  color: $dark;
  background: #eef2f5;
  max-height: 100vh;
  overflow: hidden;

  &.dark {
    background: $dark;
    color: #ddd;

    input,
    textarea {
      background: $dark;

      &:focus {
        border-color: $orange;
      }
    }

    .checkbox {
      &:checked {
        background: $gradOrange;
      }
    }

    .novas {
      svg {
        path {
          fill: #ffffff;
        }
      }
    }

    .btn {
      background: $gradOrange;

      &.btn-disabled {
        background: $grey;
      }
    }

    .section-back {
      color: #ddd;

      &:hover {
        color: $orange;
      }
    }

    .p404 {
      &__number,
      &__ico {
        .light {
          display: none;
        }

        .dark {
          display: inline-block;
        }
      }
    }

    .side {
      background: $dark;
      border-right: 1px solid rgba(163, 178, 189, 0.5);

      &-logo {
        color: #ddd;

        &:hover {
          color: #ddd;
        }
      }

      &-menu {
        a {
          color: #ddd;

          &:hover {
            color: $orange;
            background-color: $grey;
          }

          &.current {
            background: $gradOrange;
            &:hover {
              color: #ddd;
            }
          }
        }
      }

      &__close {
        &:hover {
          opacity: 1;
        }
      }

      &-mode {
        border: none;
        background: $darkBg;

        button {
          color: #ddd;
        }
      }

      &-plan {
        &__title {
          color: $orange;
        }

        &__list a {
          color: #ddd;
        }

        &__item.active {
          border-color: $orange;
          background: rgba($color: $orange, $alpha: 0.25);
        }

        &__test li {
          &.true,
          &.false,
          &.advice {
            background: $darkBg;
          }

          &.current {
            background: $gradOrange;
          }
        }

        &__close {
          background: $grey;
        }
      }

      &-stat__item.current {
        background: $darkBg;
      }
    }

    .content {
      &::-webkit-scrollbar-thumb {
        background: $orange;
      }
    }

    .mobile-menu {
      background: $dark;

      &__head {
        background: $darkBg;
      }

      &__list {
        background: $darkBg;

        a {
          color: #ddd;
        }
      }
    }

    .header {
      background: $dark;
      border-bottom: 1px solid rgba($color: $grey, $alpha: 0.5);

      &__side:hover {
        color: $orange;
      }

      &-user {
        color: $dark;
      }

      &-nots {
        background: $darkBg;
        transition: all 0.5s ease-in-out;
        &.hide {
          opacity: 0;
          display: none;
          transition: all 0.5s ease-in-out;
        }
        &.open {
          opacity: 1;
          display: block;
          transition: all 0.5s ease-in-out;
        }

        &__btn {
          color: #ddd;

          &:hover {
            color: $orange;
          }
        }
      }

      &-search {
        &__result {
          background: $darkBg !important;

          &::-webkit-scrollbar-thumb {
            background: $orange;
          }

          ul {
            li {
              border-color: rgba(163, 178, 189, 0.5);

              a {
                color: #ddd;

                &:hover {
                  color: $orange;
                }
              }
            }
          }
        }
      }
    }

    .login {
      &-form {
        &__input input {
          border: 1px solid $grey;
        }

        &__check {
          input {
            border-color: #ddd;

            &:checked {
              background: $orange;
            }
          }
          a {
            color: $orange;
          }
        }
      }
    }

    .mobile-library {
      background: $darkBg;
    }

    .library {
      &-item {
        color: #ddd;
        background: $darkBg;

        &:hover {
          color: $orange;
        }

        &.current {
          background: $gradOrange;

          &:hover {
            color: #ddd;
          }
        }
      }

      &-btn {
        .btn {
          background: $grey;

          &:hover {
            background: $darkBg;
          }
        }
      }

      &-gallery {
        .close {
          width: 0 !important;
          min-width: 0 !important;
          height: 100%;
          overflow: hidden;
          transition: all 0.5s ease-in-out;
        }

        &__text {
          @include trans();
          width: 20rem;
          height: 100%;
          overflow: scroll;
          transition: all 0.5s ease-in-out;
          &__content {
            min-width: 20rem;
            height: 100%;
            overflow: hidden;
          }
          &::-webkit-scrollbar-thumb {
            background: $orange;
          }
        }
      }

      &-article {
        color: #ddd;
        background: $darkBg;

        &:hover {
          color: $orange;
        }
      }

      &-setting {
        &__content {
          background: $dark;
        }

        &__title {
          background: $darkBg;
        }
      }

      &-accordion {
        background: $darkBg;

        &__item.active .library-accordion__head {
          background: $gradOrange;
        }

        &__head {
          border-color: rgba($color: $grey, $alpha: 0.5);
        }
      }

      &-hint {
        background: $dark;
        box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.15);
      }

      &-mobile__plan .side-plan {
        background: $dark;
      }

      &-popup__content {
        background: $dark;
      }

      &-head__btn {
        label {
          background: #fff;

          span {
            background: $dark;
          }
        }
      }
    }

    .study {
      &-list__item {
        color: #ddd;
        background: $darkBg;

        &:hover {
          color: $orange;
        }
      }

      &-item {
        color: #ddd;
        background: $darkBg;

        &.active {
          .study-item__head {
            background: $gradOrange;
          }

          &:hover {
            .study-item__head {
              color: #ddd;
            }
          }
        }

        &:hover {
          color: #ddd;

          .study-item__head {
            color: $orange;
          }
        }

        &__head {
          background: rgba($color: $grey, $alpha: 0.2);
        }

        &__line span {
          background: $gradOrange;
        }
      }

      &-questions {
        &__list {
          background: $darkBg;
        }

        &__item {
          border-color: rgba($color: $grey, $alpha: 0.5);
        }
      }

      &-themes {
        &__list {
          background: $darkBg;
        }

        &__check .checkbox {
          background: transparent;

          &:checked {
            background: $gradOrange;

            & + span {
              color: $orange;
            }
          }
        }

        &__item {
          border-color: rgba($color: $grey, $alpha: 0.5);
          a {
            color: #ddd;
          }
        }
      }

      &-test {
        &__nav {
          border: 1px solid rgba($color: $grey, $alpha: 0.2);
          bottom: 0;
          button {
            background: $dark;

            &.disabled {
              background: #2c323e;
            }

            &.active {
              background: $gradOrange;
            }
          }
        }

        &__head li {
          &:hover {
            color: $orange;
          }

          &.current {
            border-color: $orange;
            color: #ddd;

            &:hover {
              color: #ddd;
            }
          }
        }

        &__wrap button.active::after {
          background: $gradOrange;
        }

        &__card {
          background: $darkBg;
        }

        &__advice {
          background: $dark;
        }

        &__side {
          background: $darkBg;
          &_wrapper {
            background: $darkBg;
          }
          &-close {
            background: $dark;
          }
        }

        &__table {
          &::-webkit-scrollbar-thumb {
            background: $orange;
          }
        }
      }

      &-quest__item {
        &.true,
        &.false,
        &.advice {
          color: $dark;
        }

        &.choosen .study-quest__head span {
          color: #ddd;
          border-color: #ddd;
        }
      }
    }

    .news {
      &-head__btn {
        background: #fff;

        &.active {
          background: $gradOrange;
          color: #ddd;
        }
      }
      &-item {
        background: $darkBg;
        color: $lightBorder;

        &:hover {
          color: $orange;
        }

        &__title {
        }
      }

      &-single {
        &__quote {
          background: $darkBg;
        }
      }
    }

    .account {
      &-side {
        background: $darkBg;
      }

      &-head {
        background: $darkBg;

        li.current {
          background: $gradOrange;
        }
      }

      &-payment {
        &__item {
          background: $darkBg;
        }

        &__price {
          color: $orange;
        }
      }

      &-settings {
        background: $darkBg;

        &__info {
          color: #ddd;

          &:hover {
            color: $orange;
          }
        }

        &__btn {
          background: $gradOrange;
        }
        &__input {
          input,
          select,
          option {
            background-color: $dark;
          }
        }
      }

      &-message {
        background: $darkBg;
      }

      &-popup {
        &__content {
          background: $darkBg;
        }

        &__img {
          img.account-popup__img-light {
            display: none;
          }

          img.account-popup__img-dark {
            display: inline-block;
          }
        }
      }
    }
    .pagination_previous,
    .pagination_previous_a,
    .pagination_next,
    .pagination_next_a {
      color: $orange !important;
      cursor: pointer;
      a {
        color: $orange !important;
        cursor: pointer;
      }
    }
    .pagination_previous a, .pagination_next a {
      background-color: $orange;
    }

    .pagination_page {
      border-color: $orange;

      &_a {
        color: #ddd;
        &:hover {
          color: $orange;
        }
      }
    }

    .pagination_active {
      background: $gradOrange;
      cursor: pointer;
      &_a {
        color: $lightBorder;
      }
    }
    .rate {
      &-card {
        background: $darkBg;
      }

      &-head {
        &__list {
          background: $darkBg;

          .current {
            background: $gradOrange;
          }
        }
      }

      &-item {
        background: $gradOrange;

        &__card {
          background: $darkBg;
        }

        &__list li.active {
          color: #ddd;

          .rate-item__check {
            background: $gradOrange;
          }
        }
      }
    }

    .help {
      &-side {
        background: $darkBg;

        &__btn {
          color: #ddd;
          border-color: $orange;

          &:hover {
            color: $orange;
          }
        }
      }

      &-main {
        background: $darkBg;
      }
    }

    .stats {
      &-head {
        background: $darkBg;
      }

      &-main {
        background: $darkBg;
      }

      &-info__btns .btn-white {
        border-color: #ddd;
      }

      &-popup {
        &__content {
          background: $dark;
        }

        &__choose label input {
          border-color: $lightBorder;
        }

        &__range-value span {
          border-color: #ddd;
          color: #ddd;
        }
      }
    }

    .footer {
      background: $darkBg;

      &-menu {
        a.current {
          color: $orange;
          span {
            color: $orange;
          }

          &::after {
            background: $gradOrange;
          }

          svg {
            color: $orange;
          }
        }
      }

      &-library .footer-menu a {
        color: #ddd;

        &.disabled {
          opacity: 0.3;
        }
      }
    }
  }
}

.checkbox {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.2rem;
  appearance: none;
  flex-shrink: 0;
  position: relative;

  &::after {
    @include absoluteCenter();
    color: #ddd;
    font-size: 0.8rem;
  }

  &:checked {
    border: none;
    background: $gradGreen;

    &::after {
      content: '✔';
    }
  }
}

.btn {
  @include centerInline();
  border-radius: 0.5rem;
  background: $gradGreen;
  color: #ddd;
  @include trans();
  border: none;

  &:hover {
    opacity: 0.8;
    color: #ddd;
  }

  &.btn-disabled {
    background: $grey;
    pointer-events: none;
  }
}

.section-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.section-subtitle {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.section-back {
  display: inline-flex;
  margin-bottom: 1.5rem;
  color: $dark;
  font-size: 0.8rem;

  svg {
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.25rem;
  }

  &:hover {
    color: $green;
  }
}

.footer {
  display: none;
}

.p404 {
  height: 100dvh;
  width: 100%;
  @include center();
  flex-direction: column;

  &__logo {
    width: 14rem;
    margin-bottom: 1rem;
    text-align: center;

    svg {
      width: 14rem;
      height: 4.5rem;
    }
  }

  &__number {
    margin-bottom: 3rem;

    svg {
      width: 15rem;
    }

    .dark {
      display: none;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 4rem;
  }

  &__ico {
    margin-bottom: 6rem;

    svg {
      width: 100%;
    }
    .dark {
      display: none;
    }
  }

  &__btn {
    height: 3rem;
    width: 20rem;
    font-size: 1.1rem;
  }
}

//__________APP___________

.app {
  display: flex;
}

.side {
  width: 17.25rem;
  flex-shrink: 0;
  background: #fff;
  padding: 2rem 1.5rem 2.5rem;
  position: relative;
  height: 100vh;

  &-content {
    height: calc(100% - 10rem);
    display: flex;
    width: 100%;
    @include trans();
  }

  &-wrap {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;

    &__hidden {
      width: 3.2rem;

      .side-menu a {
        span {
          display: none;
          text-wrap: nowrap;
        }
      }

      .side-mode {
        width: 100%;
        padding: 0;
        border: none;
        height: 3.2rem;

        button {
          display: none;
          width: 100%;
          height: 3.2rem;
          border-radius: 0.5rem;

          &.current {
            display: flex;

            span {
              display: none;
            }
          }
        }
      }
    }
  }

  &__close {
    width: 2.3rem;
    height: 3.3rem;
    position: absolute;
    right: -1.15rem;
    top: 9rem;
    z-index: 2;

    svg {
      width: 0.8rem;
      @include trans();
    }

    &.closed {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &-logo {
    text-align: center;
    color: $dark;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    &__ico {
      width: 1.4rem;

      svg {
        width: 100%;
      }
    }

    &__text {
      width: 7.1rem;

      svg {
        width: 100%;
      }
    }

    &:hover {
      color: $dark;
    }
  }

  &-menu {
    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 1rem;
      color: $dark;
      gap: 0.8rem;
      border-radius: 0.5rem;
      span {
        text-wrap: nowrap;
        align-self: flex-end;
        overflow: hidden;
      }

      &:hover {
        color: $green;
        background-color: #eef2f5;
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
        transition: all 0s;
      }

      &.current {
        background: $gradGreen;
        color: #ddd;
      }
    }
  }

  &-mode {
    display: flex;
    width: 11.6rem;
    margin: 0 auto;
    border: 1px solid #eef2f5;
    padding: 0.15rem;
    border-radius: 3rem;
    height: 2.5rem;
    font-size: 0.8rem;

    button {
      width: 50%;
      border-radius: 3rem;
      @include center();
      gap: 0.4rem;
      color: $dark;

      svg {
        width: 0.9rem;
        height: 0.9rem;
      }
    }

    .current {
      color: #ddd;
    }

    .light.current {
      background: $gradGreen;
    }

    .dark.current {
      background: $gradOrange;
    }
  }

  &-plan {
    font-size: 0.8rem;
    border-left: 1px solid $lightBorder;
    position: relative;
    min-width: 12.5rem;

    &__close {
      position: absolute;
      top: 10rem;
      right: -0.65rem;
      z-index: 2;
      background: $grey2;
      @include centerInline();
      width: 1.3rem;
      height: 1.3rem;
      border-radius: 50%;
      cursor: pointer;

      svg {
        width: 0.8rem;
        height: 0.8rem;
        @include trans();
      }

      &:hover {
        background: $grey;
      }

      &.closed svg {
        transform: rotate(180deg);
      }
    }

    &__title {
      padding: 1rem 1rem 0.5rem;
      color: $green;
      font-weight: 600;
    }

    &__list {
      a {
        color: $dark;
      }
    }

    &__item {
      padding: 0.5rem;
      border-left: 0.25rem solid transparent;
      margin-bottom: 0.5rem;
      cursor: pointer;

      &.active {
        background: rgba($color: #7eb940, $alpha: 0.25);
        border-color: $green;

        .side-plan__sublist {
          display: block;
        }
      }
    }

    &__sublist {
      padding-left: 0.5rem;
      display: none;

      li {
        margin-top: 1rem;
        display: flex;
        align-items: flex-start;

        &::before {
          content: '\2022';
          font-size: 1rem;
          margin-right: 0.4rem;
          flex-shrink: 0;
          transform: translateY(-0.1rem);
        }
      }
    }

    &__test {
      li {
        padding: 0.5rem;
        padding-right: 2.5rem;
        display: flex;
        align-items: center;
        white-space: nowrap;
        position: relative;

        &::after {
          position: absolute;
          content: '...';
          right: 1.7rem;
        }

        span {
          text-overflow: ellipsis;
          max-width: 7.8rem;
          overflow: hidden;
          width: 100%;
        }

        &.true {
          background: #f8fafc;

          .side-plan__check {
            background: $green;

            &::after {
              background-image: url(../img/icons/check.svg);
            }
          }
        }

        &.false {
          background: #f8fafc;

          .side-plan__check {
            background: $red;

            &::after {
              background-image: url(../img/icons/close.svg);
            }
          }
        }

        &.advice {
          background: #f8fafc;

          .side-plan__check {
            background: #edbf67;

            &::after {
              background-image: url(../img/icons/check.svg);
            }
          }
        }

        &.current {
          color: #ddd;
          background: $gradGreen;
        }
      }
    }

    &__check {
      width: 1.2rem;
      height: 1.2rem;
      flex-shrink: 0;
      margin-right: 0.5rem;
      border-radius: 50%;
      @include centerInline();

      &::after {
        width: 0.8rem;
        height: 0.8rem;
        background: url() center center no-repeat;
        background-size: contain;
        content: '';
      }
    }
  }

  &-time {
    position: absolute;
    bottom: 0;
    width: 100% !important;

    button {
      @include hoverBtn();
    }

    &__wrap {
      border-top: 1px solid $lightBorder;
      border-bottom: 1px solid $lightBorder;
      display: flex;
    }

    &__left {
      width: 100%;
      height: 3rem;
      display: flex;
      padding: 0.5rem;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }

    &__time {
      text-align: center;
      color: $grey;
    }

    &__close {
      @include centerInline();

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    &__pause {
      height: 3rem;
      width: 2.5rem;
      flex-shrink: 0;
      border-left: 1px solid $lightBorder;
      @include centerInline();

      button {
        @include centerInline();

        img {
          width: 1.2rem;
        }
      }
    }

    &__end {
      width: 100%;
      border-bottom: 1px solid $lightBorder;
      @include centerInline();
      height: 2.5rem;
      font-size: 0.8rem;
    }
  }

  &-stat {
    &__item {
      padding: 0.5rem 1rem;
      cursor: pointer;

      &.current {
        background: #f8fafc;
      }
    }

    &__text {
      font-size: 0.7rem;
      margin: 0.5rem 0;
    }

    .study-item__info {
      font-size: 0.7rem;
    }

    .study-item__ico {
      width: 0.8rem;
      margin-right: 0.5rem;

      svg {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }

  &.side-mini {
    width: 6.2rem;

    .side {
      &-logo__text {
        display: none;
      }

      &-wrap {
        width: 3.2rem;

        .side-menu a {
          span {
            display: none;
            text-wrap: nowrap !important;
          }
        }

        .side-mode {
          width: 100%;
          padding: 0;
          border: none;

          button {
            display: none;
            width: 100%;
            height: 3.2rem;
            border-radius: 0.5rem;

            &.current {
              display: flex;

              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .novas {
    display: block;
    svg {
      display: block;
      width: 100%;
      max-width: 4.2rem;
      height: auto;
      margin: 0 auto;
    }
  }
}

.main {
  width: 100%;
  position: relative;
  height: 100vh;
}

.content {
  height: calc(100vh - 5.5rem);
  padding: 2rem 1.5rem;
  //   max-width: 79rem;
  //overflow-y: scroll;
  margin: 0 auto;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
  }

  &::-webkit-scrollbar-thumb {
    background: $green;
  }
}

//_________MOBILE-MENU__________

.mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: $lightBorder;
  transform: translateX(100%);
  transition: 0.7s all;

  &__head {
    @include spaced();
    background: #fff;
    padding: 1.2rem;
    border-radius: 0 0 1.2rem 1.2rem;
    margin-bottom: 2rem;
  }

  &__wrap {
    display: flex;
    align-items: center;
    gap: 0.85rem;
  }

  &__btn {
    display: flex;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .header-lang {
    display: flex;
    padding: 0.5rem;
    top: 1rem;
    right: 6rem;
  }

  &__list {
    background: #fff;
    border-radius: 0.6rem;

    li {
      display: block;
    }

    a {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 1.2rem;
      gap: 1rem;
      color: $dark;
    }
  }

  .side-mode {
    border-color: #ddd;
    background: #fff;
    @include absoluteCenterX();
    bottom: 6rem;
    //bottom: 2rem;
  }
  .novas {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    svg {
      display: block;
      width: 100%;
      max-width: 8.2rem;
      height: auto;
      transition: .3s all;
      path {
        transition: .3s all;
      }
    }
  }

  &.open {
    transform: none;
  }
}

//__________LOGIN___________

.login {
  min-height: 100vh;
  overflow: scroll;
  @include center();
  position: relative;

  &-reg {
    flex-direction: column;
    padding: 1.5rem 0;

    .login__logo {
      position: static;
      margin-bottom: 1.2rem;
    }
  }

  &__logo {
    position: absolute;
    top: 4.5rem;
    width: 9.4rem;

    svg {
      width: 100%;
      display: block;
    }
  }

  &-content {
    width: 20rem;
  }

  &__title {
    text-align: center;
  }

  &-form {
    font-size: 1.1rem;

    &__item {
      margin-bottom: 1.5rem;
    }

    &__text {
      color: $grey;
      margin-bottom: 0.25rem;
    }

    &__input {
      position: relative;

      input {
        width: 100%;
        height: 3rem;
        border-radius: 0.5rem;
        padding: 0 0.85rem;
        border: none;

        &::placeholder {
          color: rgba($color: $grey, $alpha: 0.5);
        }
      }
    }

    &__password {
      svg {
        cursor: pointer;
        width: 1.05rem;
        @include absoluteCenterY();
        right: 0.8rem;
      }

      input {
        padding-right: 2.5rem;
      }
    }

    &__check {
      display: inline-flex;
      align-items: center;
      font-size: 1rem;
      margin-bottom: 1.5rem;
      cursor: pointer;
      user-select: none;

      input {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 0.2rem;
        border: 0.1rem solid $dark;
        appearance: none;
        margin-right: 0.8rem;
        flex-shrink: 0;
        position: relative;

        &::after {
          @include absoluteCenter();
          color: #ddd;
          font-size: 0.8rem;
        }

        &:checked {
          border: none;
          background: $gradGreen;

          &::after {
            content: '✔';
          }
        }
      }
    }

    &__enter {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }

    &__btn {
      width: 100%;
      height: 3rem;
      background: $gradGreenDark;
    }
  }

  .account {
    &-popup {
      &__text {
        position: relative;
        overflow-x: hidden;
        max-height: 30rem;
        overflow-y: scroll;
        text-align: left;
        &::-webkit-scrollbar {
          width: 0.5rem;
          height: 0.5rem;
        }

        &::-webkit-scrollbar-track {
          background: #ddd;
        }

        &::-webkit-scrollbar-thumb {
          background: $green;
        }
      }
    }
  }
}

//__________HEADER___________

.header {
  height: 5.5rem;
  background: #fff;
  @include spaced();
  padding: 0 1.5rem;

  &__logo {
    display: none;
  }

  &-wrap {
    display: flex;
    align-items: center;
  }

  &__side {
    color: $grey2;
    margin-right: 1.5rem;

    &:hover {
      color: $green;
      background: transparent;
    }

    svg {
      width: 1.5rem;
      display: block;
    }
  }

  &-search {
    position: relative;

    &__input {
      position: relative;

      svg {
        @include absoluteCenterY();
        right: 0.75rem;
        color: $grey2;
        width: 1.5rem;
      }

      svg:first-child {
        @include absoluteCenterY();
        left: 0.75rem;
        color: $grey2;
        width: 1rem;
      }

      input {
        padding-left: 2.5rem;
        padding-right: 1rem;
        width: 24.5rem;
        height: 2.5rem;
      }
    }

    &__result {
      position: absolute;
      padding: 1rem;
      background: #fff !important;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      width: 24.5rem;
      max-height: 20rem;
      overflow: auto;
      z-index: 100;

      &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
      }

      &::-webkit-scrollbar-track {
        background: #ddd;
      }

      &::-webkit-scrollbar-thumb {
        background: $green;
      }

      ul {
        li {
          border-bottom: 1px solid #ccc;
          margin-bottom: 0.5rem;
          padding-bottom: 0.5rem;

          a {
            color: $dark;

            div {
              font-weight: 700;
              font-size: 0.9rem;
            }

            p {
              opacity: 0.9;
              font-size: 0.8rem;
            }

            &:hover {
              color: $green;
            }
          }

          &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border: none;
          }
        }
      }
    }
  }

  &-lang {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 8.5rem;
    top: 1.5rem;
    border-radius: 1rem;
    background: #eef2f5;
    font-size: 0.8rem;
    padding: 0.85rem 0.5rem;

    &:hover {
      .header-lang__item {
        display: flex;
      }
    }

    &__item {
      color: $dark;
      align-items: center;
      margin-top: 0.5rem;
      display: none;

      &:first-child {
        margin-top: 0;
        display: flex;
      }

      img {
        width: 1.1rem;
        height: 0.8rem;
        margin-right: 0.25rem;
      }

      span {
        min-width: 1.2rem;
      }

      &:hover {
        color: $green;
      }
    }
  }

  &__nots {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin-right: 1rem;
    position: relative;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .count {
    //   padding: 1rem;
    //   background-color: $darkBg;
    display: inline-table;
    vertical-align: middle;
    width: 1.2rem;
    height: 1.2rem;
    background-color: $grey2;
    border-radius: 50%;
    position: absolute;
    right: -0.1rem;
    top: -0.1rem;
    span {
      font-size: medium;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
  }

  &-nots {
    position: absolute;
    top: 4.5rem;
    background: $lightBorder;
    width: 12rem;
    right: 4rem;
    padding: 0.75rem 0.4rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    z-index: 100;
    transition: all 0.5s ease-in-out;
    &.hide {
      opacity: 0;
      display: none;
      transition: all 0.5s ease-in-out;
    }
    &.open {
      opacity: 1;
      display: block;
      transition: all 0.5s ease-in-out;
    }

    li {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      color: $dark;
      font-size: 0.7rem;
      gap: 0.4rem;
      text-align: left;

      &:hover {
        color: $green;
      }

      img {
        width: 2rem;
        border-radius: 0.3rem;
        flex-shrink: 0;
      }

      div {
        p {
          font-weight: 700;
        }
      }
    }
  }

  &__user {
    width: 2.5rem;
    height: 2.5rem;
    @include centerInline();
    background: $lightBorder;
    cursor: pointer;
    border-radius: 50%;
    color: $dark;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    img {
      @include fullImg();
      border-radius: 50%;
    }
  }

  &-user {
    position: absolute;
    top: 4.5rem;
    background: $lightBorder;
    //width: 5.5rem;
    width: 100%;
    max-width: 6rem;
    padding: 0.75rem 0.4rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    z-index: 100;

    li {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: flex;
      align-items: center;
      color: $dark;
      font-size: 0.8rem;
      gap: 0.25rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  &-mobile {
    display: none;
  }
}

//___________LIBRARY___________

.mobile-library {
  display: none;
}

.library {
  &-list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 1.5rem;
    margin-bottom: 1.5rem;

    li {
      width: calc(20% - 1.2rem);
    }
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 0.9rem;
    background: #fff;
    color: $dark;
    border-radius: 0.5rem;
    gap: 0.6rem;
    height: 100%;

    span {
      @include ellipsis();
      -webkit-line-clamp: 3;
      line-height: 1rem;
      max-height: 3rem;
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
      flex-shrink: 0;
    }

    &:hover {
      color: $green;
    }

    &.current {
      background: $gradGreen;
      color: #ddd;
    }
  }

  &-article {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0.9rem;
    background: #fff;
    color: $dark;
    border-radius: 0.5rem;
    gap: 0.6rem;
    font-size: 0.9rem;
    height: 100%;

    svg {
      width: 1.2rem;
      height: 1.2rem;
      flex-shrink: 0;
    }

    span {
      @include ellipsis();
      -webkit-line-clamp: 3;
      line-height: 0.9rem;
      max-height: 2.7rem;
    }

    &:hover {
      color: $green;
    }
  }

  &-head {
    @include spaced();
    margin-bottom: 1rem;
    font-size: 0.8rem;

    .section-title {
      margin-bottom: 0;
    }

    &__wrap {
      display: flex;
      align-items: center;

      button {
        @include hoverBtn();
      }
    }

    &__lang {
      display: flex;
      margin-right: 2rem;

      button {
        margin-right: 0.75rem;
        display: flex;
        align-items: center;

        &:last-child {
          margin-right: 0.75rem;
        }

        img {
          width: 1.1rem;
          margin-right: 0.25rem;
        }

        &.current {
          color: $green;
          text-decoration: underline;
        }
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      margin-right: 2rem;

      &:last-child {
        margin-right: 0;
      }

      svg {
        cursor: pointer;
        @include hoverBtn();
      }

      img {
        width: 1.1rem;
      }

      label {
        cursor: pointer;
        width: 1.2rem;
        height: 0.7rem;
        padding: 1px;
        background: $grey;
        border-radius: 0.7rem;
        display: inline-flex;
        align-items: center;

        span {
          background: #fff;
          border-radius: 0.7rem;
          height: 0.6rem;
          width: 0.6rem;
          @include trans();
        }

        input {
          display: none;

          &:checked + span {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }

  &-btn {
    margin-bottom: 2rem;

    .btn {
      width: 11rem;
      height: 2.2rem;
      background: $grey;
      color: #ddd;
      gap: 0.5rem;

      img {
        width: 1.2rem;
      }

      &:hover {
        background: $dark;
      }
    }
  }

  &-accordion {
    border-radius: 0.5rem;
    overflow: hidden;
    background: #fff;

    &__item {
      &:first-child {
        .library-accordion__head {
          border: none;
        }
      }

      &.active {
        .library-accordion__head {
          background: $gradGreen;
          color: #ddd;

          svg {
            transform: rotate(180deg);
          }
        }

        .library-accordion__content {
          display: block;
        }
      }
    }

    &__head {
      border-top: 1px solid $lightBorder;
      padding: 1.15rem 1.5rem;
      @include spaced();
      cursor: pointer;
      @include trans();

      svg {
        margin-left: 4rem;
        width: 1.2rem;
        height: 1.2rem;
        flex-shrink: 0;
      }

      &:hover {
        background: rgba($color: #ddd, $alpha: 0.3);
      }
    }

    &__content {
      padding: 1.5rem;
      position: relative;
      display: none;

      ul {
        // padding-left: 1rem;

        // li {
        //   &::before {
        //     content: '\2022';
        //     margin-right: 5px;
        //   }
        // }
      }
    }
  }

  &-hint {
    background: #fff;
    position: absolute;
    z-index: 2;
    padding: 0.5rem;
    border-radius: 0.25rem;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    font-size: 0.8rem;
    width: 15rem;

    &__title {
      font-weight: 600;
      margin-bottom: 0.25rem;
    }
  }

  &-gallery {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    // display: none;

    button {
      svg {
        color: #ddd;
      }
      &:active {
        transform: scale(0.98);
        /* Scaling button to 0.98 to its original size */
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
        color: $green !important;
        svg {
          color: $green !important;
        }
        /* Lowering the shadow */
      }
    }

    &__content {
      display: flex;
      height: 100%;
      min-width: 100vw;
      max-width: 100vw;
      width: 100vw;
    }

    &__main {
      flex: 1;
      min-height: 100dvh;
      max-height: 100dvh;
      div {
        width: 100%;
        height: 100dvh;
        display: flex;
        align-items: center;
        justify-content: center;
        img,
        iframe {
          max-height: 80%;
          width: auto;
        }
      }
    }
    .close {
      width: 0 !important;
      min-width: 0 !important;
      height: 100%;
      overflow: hidden;
      transition: all 0.5s ease-in-out;
    }

    &__text {
      @include trans();
      width: 20rem;
      height: 100%;
      overflow: scroll;
      transition: all 0.5s ease-in-out;
      &__content {
        min-width: 20rem;
        height: 100%;
        overflow: hidden;
      }
      @include trans();

      &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
      }

      &::-webkit-scrollbar-track {
        background: #ddd;
      }

      &::-webkit-scrollbar-thumb {
        background: $green;
      }
    }

    &__close,
    &__edit {
      width: 4rem;
      height: 4rem;
      background: #373737;
      @include centerInline();
      border-radius: 0.5rem;
      flex-shrink: 0;
      cursor: pointer;
      transition: all 0s none;
      animation: none;
      &.active {
        background: $gradGreen;
      }
      * {
        transition: all 0s none;
        animation: none;
      }
      img {
        width: 1.5rem;
        transition: all 0s none;
        animation: none;
      }
    }

    &__btns {
      display: flex;
      position: absolute;
      left: 1rem;
      bottom: 1rem;
      justify-content: flex-end;
      transition: all 0s;
      * {
        animation: none;
        transition: all 0s;
      }
    }

    &__edit {
      margin-right: 0.5rem;
    }

    &__img {
      height: calc(100% - 10rem);
      max-width: 72rem;
      margin: 0 auto;

      img,
      video {
        @include fullImg();
        object-fit: contain;
      }
    }

    &__zoom {
      height: 4rem;
      display: flex;
      align-items: center;
      background: #373737;
      border-radius: 0.5rem;
      flex-shrink: 0;
      padding: 1.25rem;
      gap: 1.25rem;

      button {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        padding: 0;

        img {
          width: 100%;
        }
      }
    }

    &__info {
      background: $dark;
      color: #ddd;
      flex-shrink: 0;
      padding: 3rem 1.5rem;
      position: relative;
    }
    .close {
      width: 0;
      min-width: 0;
      height: 100%;
      overflow: hidden;
      transition: all 0.5s ease-in-out;
    }

    &__text {
      @include trans();
      width: 23rem;
      height: 100%;
      overflow: scroll;
      transition: all 0.5s ease-in-out;
      &__content {
        min-width: 23rem;
        height: 100%;
        overflow: hidden;
      }
      p {
        margin-bottom: 1rem;
      }
    }

    &__slide {
      position: absolute;
      bottom: 1.75rem;
      left: -1.15rem;
      width: 2.3rem;
      height: 3.3rem;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      svg {
        width: 100%;
      }
    }
  }

  &-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 7;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    display: none;

    .library-gallery__close {
      position: absolute;
      left: 1.5rem;
      top: 1rem;
    }

    &__content {
      width: 37rem;
      border-radius: 1rem;
      padding: 3.5rem 2.5rem;
      background: #fff;
      @include absoluteCenter();
    }

    &__ico {
      text-align: center;
      margin-bottom: 2.5rem;

      svg {
        display: inline-block;
        width: 10rem;
        height: 10rem;
      }
    }

    &__title {
      font-weight: 600;
    }
  }

  &-setting {
    display: none;
  }

  &-mobile__plan {
    display: none;
  }
}

//___________STUDY___________

.study {
  //   max-width: 45rem;
  margin: 0 auto;
  position: relative;
  transition: all 0.5s ease-in-out;

  &-full {
    max-width: none;
  }

  &.sliced {
    padding-right: 34rem;

    .study-test__nav {
      width: calc(100% - 54.5rem);
      max-width: 42rem;
    }

    .study-test__side {
      transform: none;
    }
  }

  &__text {
    font-size: 0.8rem;
    line-height: 135%;
    margin-bottom: 1.5rem;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    &__item {
      display: block;
      padding: 2.1rem 0.9rem 0.9rem;
      width: calc(33.3333% - 1rem);
      color: $dark;
      background: #fff;
      border-radius: 0.5rem;

      &:hover {
        color: $green;
      }
    }

    &__name {
      font-weight: 600;
      margin-bottom: 0.6rem;
    }

    &__type {
      font-size: 0.8rem;
    }
  }

  &__subtitle {
    margin-top: -1rem;
    margin-bottom: 1.5rem;
    color: $grey;
    font-weight: 700;
    font-size: 1.2rem;
  }

  &-choose {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  &-item {
    width: calc(33.333% - 1rem);
    color: $dark;
    border-radius: 0.5rem;
    overflow: hidden;
    background: #fff;

    &.active {
      .study-item__head {
        background: $gradGreen;
        color: #ddd;
      }

      &:hover {
        .study-item__head {
          color: #ddd;
        }
      }
    }

    &:hover {
      color: $dark;

      .study-item__head {
        color: $green;
      }
    }

    &__head {
      padding: 1rem;
      background: #f8fafc;
      min-height: 8rem;
    }

    &__wrap {
      padding: 1rem;
    }

    &__info {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      margin-bottom: 0.75rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__ico {
      flex-shrink: 0;
      margin-right: 0.5rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
        display: block;
      }
    }

    &__count {
      width: 100%;
    }

    &__line {
      background: #d9d9d9;
      border-radius: 2px;
      height: 3px;
      width: 100%;
      margin-top: 2px;

      span {
        background: $gradGreen;
        display: block;
        height: 100%;
        width: 0;
      }
    }
  }

  &__name {
    margin-bottom: 0.8rem;
    font-weight: 700;
    font-size: 1.2rem;
  }

  &-themes {
    margin-bottom: 1.5rem;

    &__list {
      background: #fff;
      border-radius: 0.5rem;
      overflow: hidden;
    }

    &__item {
      border-bottom: 1px solid $lightBorder;
      padding: 1rem;
      @include spaced();
      a {
        color: $dark;
      }

      &:last-child {
        border: none;
      }
    }

    &__wrap {
      display: flex;
      align-items: center;

      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.5rem;
      }
    }

    &__check {
      display: flex;
      align-items: center;
      color: $grey;
      font-size: 0.8rem;
      cursor: pointer;

      .checkbox {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        border: 0.2rem solid $grey;
        margin-right: 0.5rem;
        cursor: pointer;

        &:checked {
          border: none;

          & + span {
            color: $green;
          }
        }
      }
    }
  }

  &-questions {
    margin-bottom: 1.5rem;

    &__list {
      background: #fff;
      border-radius: 0.5rem;
      overflow: hidden;
      margin-bottom: 1rem;
    }

    &__item {
      border-bottom: 1px solid $lightBorder;
      padding: 1rem;

      &:last-child {
        border: none;
      }
    }

    &__wrap {
      display: flex;
      align-items: flex-end;

      .study-item__info {
        width: calc(100% - 7.5rem);
        margin-bottom: 0;
      }
    }

    &__btn {
      width: 7rem;
      height: 2.3rem;
      font-size: 0.8rem;
      margin-left: 0.5rem;
    }
  }

  &-test {
    position: relative;
    transition: all 0.5s ease-in-out;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 80vh;

    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-track {
      background: #ddd;
    }

    &::-webkit-scrollbar-thumb {
      background: $green;
    }
    //&.sliced {
    //  transition: all 0.5s ease-in-out;
    //  flex: 1;
    //  //width: 50%;
    //  //max-width: 50%;
    //  //min-width: 50%;
    //}

    &__edited {
      height: calc(100vh - 10rem);
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      overflow-x: hidden;

    }

    &__container {
      display: flex;
      height: 100%;
      justify-content: space-between;
      flex-direction: column;
      transition: all 0.5s ease-in;
      flex: 1;
      &.sliced {
        transition: all 0.5s ease-in-out;
        //width: 50%;
        //max-width: 50%;
        //min-width: 50%;
      }
    }

    &__card {
      background: #fff;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }

    &__text {
      padding: 1.5rem;
      font-size: 0.8rem;

      .advice {
        border-bottom: 0.1rem solid #d49738;
      }
    }

    &__flex {
      display: flex;
      gap: 0.5rem;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      button {
        min-width: 20%;
        max-width: 30%;
        img {
          width: 100%;
        }
      }
    }

    &__advice {
      padding: 0.75rem 0.75rem 0.75rem;
      background: #f8fafc;
      border-top: 1px solid $dark;
      font-size: 0.8rem;
    }

    &__buttons {
      @include spaced();
      padding: 0 1.5rem 1.5rem;
      gap: 1rem;
      flex-wrap: wrap;
    }

    &__main {
      padding-bottom: 5rem;
      //height: 100% !important;
    }

    &__wrap {
      display: flex;
      align-items: center;
      gap: 1rem;

      button {
        display: inline-flex;
        align-items: center;
        font-size: 0.9rem;
        @include hoverBtn();

        svg {
          width: 1.2rem;
          height: 1.2rem;
          margin-right: 0.6rem;
        }

        &.active {
          position: relative;

          &::after {
            position: absolute;
            width: 100%;
            height: 0.1rem;
            background: $gradGreen;
            bottom: -0.2rem;
            content: '';
          }
        }
      }
    }

    &__more {
      display: flex;
      align-items: center;
      gap: 1rem;

      button {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        gap: 0.3rem;

        svg {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }

    &__nav {
      position: relative;
      width: 100%;
      margin-top: 2rem;
      z-index: 3;
      display: flex;
      border-radius: 0.5rem;
      overflow: hidden;
      bottom: 0;
      transition: all 0.5s ease-in-out;

      &.sliced {
        //width: 50%;
        //min-width: 50%;
        //max-width: 50%;
        transition: all 0.5s ease-in-out;
      }

      button {
        width: 50%;
        height: 3.5rem;
        @include centerInline();
        background: #fff;
        position: relative;
        overflow: hidden;
        @include hoverBtn();

        &::after {
          position: absolute;
          width: 40%;
          height: 200%;
          background: rgba($color: #ddd, $alpha: 0.3);
          transform: translateX(-230%) rotate(-45deg);
          @include trans();
          content: '';
        }

        &:active {
          &::after {
            transform: translateX(230%) rotate(-45deg);
          }
        }

        svg {
          @include absoluteCenterY();
          width: 1.2rem;
          height: 1.2rem;
        }

        &.disabled {
          background: #f8fafc;
          color: $grey2;
          pointer-events: none;
        }

        &.active {
          color: #ddd;
          background: $gradGreen;

          &:hover {
            opacity: 0.9;
          }
        }
      }

      .left svg {
        left: 1.5rem;
      }

      .right svg {
        right: 1.5rem;
      }
    }

    &__main.sliced {
      width: calc(100% - 33rem);

      .study-test__nav {
        width: calc(100% - 36.5rem);
      }
    }
    &__side_wrapper {
      height: 100%;
      flex: 1;
      max-width: 100%;
      overflow: hidden;
      transition: width 0.5s ease-in-out;
      position: relative;
      background-color: #fff;
      border-radius: 0.5rem;
      padding: 1rem;
      animation-name: example;
      animation-duration: 0.5s;
    }
    @keyframes example {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }
    @keyframes example-exit {
      from {
        width: 100%;
      }
      to {
        width: 0;
      }
    }
    &__side_wrapper_close {
      width: 0;
      transition: all 0.5s ease-in-out;
      height: 100%;
      overflow: hidden;
      transition: all 0.5s ease-in-out;
      background-color: #fff;
      border-radius: 0.5rem;
      animation-duration: 0.5s;
    }
    &__side {
      //   padding: 1.5rem 1rem;
      //   border-radius: 0.5rem;
      background: #fff;
      width: 100%;
      transition: all 0.5s ease-in-out;

      height: 100%;
      //   margin-top: -4rem;
      //   position: fixed;
      //   top: 11rem;
      //   right: 0;
      //   transform: translateX(100%);
      //   @include trans();
      //   transform: translate(120%, 0);

      &-close {
        @include center();
        width: 100%;
        height: 2.5rem;
        border-radius: 0.5rem;
        background: $lightBorder;
        font-weight: 600;
      }

      &-buttons {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        button {
          width: 2rem;
          height: 2rem;
          @include centerInline();
          border: 1px solid #ccc;
          border-radius: 0.5rem;

          svg {
            width: 1rem;
          }
        }
      }

      &-result {
        @include absoluteCenterY();
        right: 0.7rem;
        font-size: 0.75rem;
      }
    }

    &__search {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__head {
      display: flex;
      margin-bottom: 1.1rem;

      li {
        width: 33.33%;
        @include centerInline();
        height: 2.1rem;
        border-radius: 0.5rem;
        font-size: 0.9rem;
        border: 0.1rem solid transparent;
        cursor: pointer;

        &:hover {
          color: $green;
        }

        &.current {
          border-color: $green;
          width: auto !important;
          padding-inline: 0.5rem;
          color: $dark;
        }
      }
    }

    &__table {
      border-radius: 0.5rem;
      border: 1px solid #a3b2bd;
      overflow: auto;
      margin-bottom: 1rem;
      height: calc(100% - 10rem);

      &::-webkit-scrollbar {
        width: 0.35rem;
        height: 0.35rem;
      }

      &::-webkit-scrollbar-track {
        background: #ddd;
      }

      &::-webkit-scrollbar-thumb {
        background: $green;
      }

      &-row {
        display: flex;

        &:last-child div {
          border-bottom: none;
        }

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.4rem 1.5rem;
          border-right: 1px solid $grey;
          border-bottom: 1px solid $grey;

          &:nth-child(1) {
            width: 40%;
          }

          &:nth-child(2) {
            width: 60%;
            border-right: 0;
          }
        }
      }

      &-head {
        font-weight: 600;
      }
    }
  }

  &-quest {
    &__item {
      border-top: 1px solid $lightBorder;

      &:last-child {
        border-bottom: 1px solid $lightBorder;
      }

      &.true {
        .study-quest {
          &__head {
            background: #84bc40;

            span {
              background: $dark;
              color: #ddd;
              border-color: $dark;
            }
          }

          &__answer {
            display: block;
            background: rgba($color: #84bc40, $alpha: 0.5);
          }
        }
      }

      &.false {
        .study-quest {
          &__head {
            background: #fe6149;

            span {
              background: $dark;
              color: #ddd;
              border-color: $dark;
            }
          }

          &__answer {
            display: block;
            background: rgba($color: #fe6149, $alpha: 0.5);
          }
        }
      }

      &.advice {
        .study-quest {
          &__head {
            background: $yellow;

            span {
              background: $dark;
              color: #ddd;
              border-color: $dark;
            }
          }

          &__answer {
            display: block;
            background: rgba($color: $yellow, $alpha: 0.5);
          }
        }
      }

      &.choosen {
        .study-quest {
          &__head {
            span {
              color: $dark;
              border-color: $dark;
            }
          }
        }
      }
    }

    &__head {
      padding: 0.85rem 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      @include trans();

      input {
        display: none;
      }

      span {
        width: 1.1rem;
        height: 1.1rem;
        border-radius: 50%;
        border: 0.1rem solid $grey;
        color: $grey;
        font-size: 0.8rem;
        @include centerInline();
        margin-right: 0.5rem;
      }

      &:hover {
        background: rgba($color: #ddd, $alpha: 0.3);
      }
    }

    &__answer {
      padding: 1rem 3rem;
      display: none;
    }
  }

  &-advice {
    @include centerInline();
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    background: $yellow;
    margin-bottom: 0.5rem;
    color: $dark;

    img {
      width: 1rem;
      margin-right: 0.5rem;
    }
  }
}

//___________NEWS____________

.news {
  .news-item {
    cursor: pointer;
  }
  &-head {
    @include spaced();
    margin-bottom: 1.5rem;

    .section-title {
      margin-bottom: 0;
    }

    &__btn {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      color: $dark;
      background: #fff;

      &:hover {
        color: $dark;
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }

      &.active {
        background: $gradGreen;
        color: #ddd;
      }
    }
  }

  &-content {
    max-width: 54rem;
    margin: 0 auto;

    .carousel {
      .control-dots {
        display: flex;
        justify-content: center;
        gap: 0.25rem;

        .dot {
          box-shadow: unset;
          background: rgba($color: #000000, $alpha: 0.2);
          &.selected {
            background: #bde2ed;
            width: 15px;
            border-radius: 10px;
          }
        }
      }
    }
  }

  &-slider {
    margin-bottom: 1.5rem;

    .carousel {
      position: relative;
      border-radius: 1rem;
      overflow: hidden;
    }

    &__wrap {
      max-width: 30rem;
      width: 100%;
      text-align: left;
    }

    &__item {
      border-radius: 1rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      padding: 1.5rem;
      height: 13.5rem;
      position: relative;
      color: #ddd;
    }

    &__category {
      margin-bottom: 0.25rem;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    }

    &__title {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0.25rem;
      text-shadow: 2px 2px 3px #00000059;
      color: #ffffff;
    }
    &__desc {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    &__btn {
      width: 6.8rem;
      height: 2.2rem;
      background: #fff;
      color: $dark;
      font-size: 0.8rem;
      border-radius: 0.5rem;
      @include centerInline();

      &:hover {
        color: $dark;
        opacity: 0.8;
      }
    }

    &__img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      img {
        @include fullImg();
      }
    }
  }

  &-item {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: stretch;
    height: 11.5rem;
    background: #fff;
    border-radius: 1rem;
    color: $dark;
    overflow: hidden;
    &:hover {
      color: $green;
      &__title {
        color: $green !important;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__img {
      width: 17rem;
      flex-shrink: 0;
      height: 100%;

      a {
        width: 100%;
        height: 100%;
        display: block;
      }

      img {
        @include fullImg();
        border-radius: 1rem;
      }
    }

    &__wrap {
      padding: 1rem 1.5rem 3.5rem;
      flex: 1;
      position: relative;
    }

    &__title {
      font-size: 1.3rem;
      margin-bottom: 0.75rem;
      font-weight: 700;
      display: block;
    }

    &__desc {
      margin-bottom: 0.75rem;
    }

    &__bot {
      @include spaced();
      position: absolute;
      bottom: 1rem;
      width: calc(100% - 3rem);
    }

    &__date {
      display: flex;
      align-items: center;
      font-size: 0.9rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.25rem;
        color: $grey2;
      }

      span {
        color: $grey2;
      }
    }

    &__mark {
      @include centerInline();

      svg {
        // width: 1.5rem;
        // height: 1.5rem;
      }
    }
  }

  &-single {
    padding-bottom: 5rem;
    .news-item__bot {
      width: 100%;
      margin-bottom: 1rem;
    }

    &__desc {
      margin-bottom: 1rem;
    }

    &__banner {
      margin-bottom: 1.5rem;

      img {
        width: 100%;
        display: block;
        border-radius: 0.5rem;
      }

      p {
        text-align: center;
        margin-top: 0.5rem;
        font-size: 1.1rem;
        font-weight: 600;
        color: $grey2;
      }
    }

    &__text {
      p {
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
      }
    }

    &__quote {
      padding: 1.5rem;
      background: #f0f0f0;
      border-radius: 0.5rem;
      margin-bottom: 1.5rem;

      p {
        padding-left: 0.75rem;
        border-left: 0.25rem solid #051ed6;
        margin-bottom: 0;
      }
    }
  }
}

//___________ACCOUNT___________

.account {
  &-content {
    display: flex;
    align-items: flex-start;
  }

  &-side {
    width: 17rem;
    padding: 1.5rem;
    background: #fff;
    border-radius: 0.5rem;
    margin-right: 1.5rem;
    position: sticky;
    top: 0;

    &__item {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__name {
      margin-bottom: 0.5rem;
      font-size: 0.8rem;
      font-weight: 500;
    }

    &__value {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      flex-wrap: wrap;
      font-weight: 700;

      img {
        //width: 1.5rem;
        //height: 1.5rem;

        width: 2.5rem;
        height: 2.5rem;
        object-fit: cover;
        border-radius: 50%;
        flex-shrink: 0;
      }
    }
  }

  &-main {
    width: calc(100% - 18.5rem);
  }

  &-head {
    margin-bottom: 1.5rem;
    display: flex;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    background: #fff;

    li {
      width: 25%;
      height: 3.2rem;
      @include centerInline();
      font-size: 0.9rem;
      cursor: pointer;
      border-radius: 0.5rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.5rem;
      }

      &.current {
        background: $gradGreen;
        color: #ddd;
      }
    }
  }

  &-search {
    margin-bottom: 1.5rem;

    .header-search__input input {
      width: 100%;
    }
  }

  &-payment {
    &__item {
      margin-bottom: 1.5rem;
      padding: 1rem;
      border-radius: 0.5rem;
      background: #fff;
    }

    &__status {
      font-weight: 700;
      margin-bottom: 0.75rem;
    }

    &__text {
      margin-bottom: 0.75rem;
    }

    &__wrap {
      @include spaced();
    }

    &__date {
      color: $grey;
    }

    &__price {
      color: $green;
    }
  }

  &-settings {
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 1.5rem;

    &__item {
      width: calc(50% - 0.75rem);
    }

    &__name {
      font-size: 0.9rem;
      margin-bottom: 0.25rem;
      color: $grey;
    }

    &__input {
      input,
      select,
      option {
        width: 100%;
        height: 2.5rem;
        padding: 0 1rem;
      }
    }

    &__file {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      height: 2.5rem;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      color: #e1e1e1;

      span {
        @include centerInline();
        width: 8.3rem;
        height: 100%;
        background: $dark;
        border-radius: 0.5rem;
        color: #ddd;
        margin-right: 1rem;
      }

      input {
        display: none;
      }
    }

    &__info {
      display: inline-flex;
      align-items: center;
      color: $dark;
      margin-top: 1.5rem;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
      }

      span {
        text-decoration: underline;
      }

      &:hover {
        color: $green;
      }
    }

    &__btn {
      width: 8.3rem;
      height: 2.5rem;
      @include centerInline();
      border-radius: 0.5rem;
      font-size: 0.8rem;
      background: $gradGreen;
      color: #ddd;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &-message {
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: #fff;

    &__text {
      margin-bottom: 1rem;

      textarea {
        width: 100%;
        height: 9rem;
        resize: none;
        padding: 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }

  &-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: rgba($color: #000, $alpha: 0.6);
    backdrop-filter: blur(10px);
    display: none;

    &__close {
      background: #373737;
      @include centerInline();
      width: 4rem;
      height: 4rem;
      border-radius: 1rem;
      cursor: pointer;
      position: absolute;
      top: 1rem;
      left: 1.5rem;

      img {
        width: 1.5rem;
      }
    }

    &__content {
      @include absoluteCenter();
      width: 30rem;
      background: #fff;
      border-radius: 1rem;
      padding: 3rem 2rem;
      text-align: center;
    }

    &__img {
      margin-bottom: 1.5rem;

      svg,
      img {
        display: inline-block;
        width: 7.5rem;
        height: 7.5rem;
      }

      img.account-popup__img-dark {
        display: none;
      }
    }

    &__text {
      margin-bottom: 1.5rem;
    }

    &__btns {
      display: flex;
      justify-content: center;
      gap: 1rem;

      .btn-grey {
        background: $grey !important;
      }
    }

    .btn {
      width: 20rem;
      height: 3.1rem;
    }
  }
}

.pagination_previous a,
.pagination_next a {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $green;
  color: #ffffff;
  cursor: pointer;
  border-radius: 50%;
  font-family: 'Gilroy';
  svg {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.pagination_page {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  @include centerInline();
  a {
    color: #121b2d;
  }
  &_a {
    &:hover {
      color: $green;
    }
  }
}
.pagination_active {
  a {
    color: $green;
  }
}
.rate {
  &-card {
    padding: 1.5rem;
    background: #fff;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
  }

  &__subtitle {
    font-weight: 700;
    margin-bottom: 0.75rem;
  }

  &-table {
    border-radius: 0.5rem;
    border: 1px solid #a3b2bd;
    overflow: hidden;

    &__row {
      display: flex;

      &:last-child div {
        border-bottom: none;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        border-right: 1px solid $grey;
        border-bottom: 1px solid $grey;
        text-align: center;

        &:nth-child(1) {
          width: 40%;
        }

        &:nth-child(2) {
          width: 60%;
        }

        &:nth-child(3) {
          width: 60%;
        }

        &:nth-child(4) {
          width: 60%;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    &__head {
      font-weight: 600;
    }
  }

  &-devices {
    &__item {
      margin-bottom: 0.75rem;
      background: $dark;
      color: #ddd;
      @include spaced();
      padding: 1rem;
      border-radius: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__wrap {
      display: flex;
      align-items: center;

      img {
        width: 1.75rem;
        margin-right: 0.9rem;
      }
    }

    &__btn {
      height: 3rem;
      width: 10.2rem;
      background: #fff;
      color: $dark;
      border-radius: 0.5rem;
      @include centerInline();

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &-head {
    @include spaced();
    margin-bottom: 1.5rem;

    .section-title {
      margin-bottom: 0;
    }

    &__list {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      background: #fff;
      border-radius: 0.5rem;
      display: flex;

      button {
        @include centerInline();
        width: 4.8rem;
        height: 2rem;
        font-size: 0.9rem;
        border-radius: 0.5rem;
      }

      .current {
        background: $gradGreen;
        color: #ddd;
      }
    }
  }

  &-wrap {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    justify-content: center;
  }

  &-item {
    width: 23rem;
    padding: 0.15rem;
    background: $gradGreen;
    border-radius: 0.75rem;

    &__card {
      border-radius: 0.7rem;
      background: #fff;
      padding: 1.5rem;
    }

    &__head {
      @include spaced();
      margin-bottom: 1.4rem;
      gap: 1.5rem;
    }

    &__name {
      font-size: 1.8rem;
      font-weight: 700;
      margin-bottom: 1.4rem;
    }

    &__text {
      font-size: 1.1rem;
      font-weight: 600;
    }

    &__ico {
      width: 5rem;
      flex-shrink: 0;

      img {
        width: 100%;
        display: block;
      }
    }

    &__list {
      li {
        margin-bottom: 1.4rem;
        display: flex;
        align-items: center;
        color: #bababa;
        gap: 0.8rem;

        &.active {
          color: $dark;

          .rate-item__check {
            border: none;
            background: $gradGreen;

            &::after {
              content: '✔';
            }
          }
        }
      }
    }

    &__check {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 0.2rem;
      appearance: none;
      flex-shrink: 0;
      position: relative;
      border: 0.15rem solid #bababa;
      flex-shrink: 0;

      &::after {
        @include absoluteCenter();
        color: #ddd;
        font-size: 0.8rem;
      }
    }

    &__price {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }

    &__btn {
      height: 3.2rem;
      font-size: 1rem;
      width: 100%;
    }
  }
}

.message {
  width: 25rem;
  padding: 1.5rem;
  @include centerInline();
  border-radius: 1rem;
  color: #ddd;
  font-size: 1.2rem;
  position: relative;

  &__ico {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.85rem;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__close {
    color: $dark;
    width: 1.4rem;
    height: 1.4rem;
    background: #fff;
    border-radius: 50%;
    @include centerInline();
    position: absolute;
    right: -1.6rem;
    z-index: 1;
    top: 0;
    cursor: pointer;

    svg {
      width: 0.6rem;
      height: 0.6rem;
    }
  }

  &-success {
    background: linear-gradient(180deg, #84bc3f 0%, #4fa446 100%);
  }

  &-error {
    background: $red;
  }

  &-yellow {
    background: linear-gradient(180deg, #f9c304 0%, #ff9120 100%);
  }

  &-ends {
    background: #fb664f;
  }

  &-warn {
    background: linear-gradient(180deg, #ff624a 0%, #d11c00 100%);
  }
}

//___________HELP____________

.help {
  &-content {
    display: flex;
    align-items: flex-start;
  }

  &-side {
    position: sticky;
    top: 1rem;
    width: 20.5rem;
    margin-right: 1.5rem;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: #fff;

    .section-title {
      text-align: center;
    }

    .account-settings__item {
      width: 100%;
      margin-bottom: 1rem;

      input {
        width: 100%;
      }

      textarea {
        width: 100%;
        padding: 0.65rem 1rem;
        height: 10.3rem;
        resize: none;
      }
    }

    &__btn {
      width: 100%;
      height: 3rem;
      border-radius: 0.5rem;
      border: 0.1rem solid $green;
      color: $dark;
      @include centerInline();

      &:hover {
        color: $green;
      }
    }
  }

  &-main {
    width: calc(100% - 22rem);
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: #fff;

    &__text {
      font-size: 1.1rem;
      line-height: 150%;

      p {
        margin-bottom: 2rem;
      }
    }
  }
}

//__________STATS____________

.stats {
  &-content {
    max-width: 45rem;
    margin: 0 auto;
  }

  &-head {
    background: #fff;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    @include spaced();
    text-align: center;
    margin-bottom: 1.5rem;

    &__value {
      font-size: 1.2rem;
      font-weight: 700;
      margin-bottom: 0.2rem;
    }

    &__name {
      display: flex;
      align-items: center;

      img {
        width: 1.2rem;
        margin-right: 0.3rem;
      }
    }
  }

  &-main {
    background: #fff;
    border-radius: 0.5rem;
    padding: 2rem;

    &__title {
      margin-bottom: 1.5rem;
      font-size: 1.2rem;
      font-weight: 600;
    }

    &__wrap {
      display: flex;
    }
  }

  &-circle {
    width: 32%;
    margin-right: 3%;
  }

  &-info {
    width: 65%;
    max-width: 20rem;

    &__title {
      font-size: 0.9rem;
      font-weight: 600;
      margin-bottom: 1rem;
      color: $grey;
    }

    &__item {
      margin-bottom: 1rem;
      display: flex;
      align-items: flex-start;
    }

    &__color {
      width: 1.6rem;
      height: 0.75rem;
      border-radius: 0.1rem;
      margin-right: 0.65rem;
      flex-shrink: 0;
      margin-top: 0.2rem;
    }

    &__text {
      font-size: 0.9rem;

      span {
        color: $grey;
      }
    }

    &__btns {
      display: flex;
      gap: 0.5rem;
      font-size: 0.7rem;

      .btn-white {
        width: 50%;
        height: 2rem;
        border: 1px solid $dark;
        border-radius: 0.25rem;

        &:hover {
          opacity: 0.8;
        }
      }

      .btn {
        width: 50%;
        height: 2rem;
        border-radius: 0.25rem;
      }
    }
  }

  &-popup {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
    display: none;

    &__close {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      cursor: pointer;

      svg {
        width: 1.1rem;
        height: 1.1rem;
      }
    }

    &__content {
      width: 31rem;
      background: #fff;
      @include absoluteCenter();
      border-radius: 0.5rem;
      padding: 2rem 1.5rem;
    }

    &__title {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $lightBorder;
      font-size: 1.5rem;
      padding-right: 2rem;
      font-weight: 700;
    }

    &__item {
      padding: 1.5rem 0;
      border-bottom: 1px solid $lightBorder;
    }

    &__subtitle {
      color: $grey;
      margin-bottom: 0.8rem;
      font-weight: 700;
      font-size: 0.9rem;
    }

    &__course {
      font-size: 0.8rem;
    }

    &__choose {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.8rem;

      label {
        display: flex;
        align-items: center;
        font-size: 0.8rem;

        input,
        select,
        option {
          width: 0.9rem;
          height: 0.9rem;
          border-color: $dark;
          border-radius: 50%;
          margin-right: 0.5rem;

          &::after {
            font-size: 0.7rem;
          }
        }

        span {
          display: flex;
          align-items: center;

          &::before {
            content: '\2022';
            font-size: 1rem;
            margin-right: 0.5rem;
          }
        }

        &.yellow {
          color: #ecbe67;
        }

        &.red {
          color: $red;
        }

        &.green {
          color: $green;
        }
      }
    }

    &__range {
      @include spaced();

      &-input {
        width: 70%;
      }

      &-value {
        text-align: right;
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        color: $grey;

        span {
          padding: 0.1rem 0.7rem;
          border: 1px solid $dark;
          color: $dark;
          border-radius: 0.25rem;
          margin-right: 0.3rem;
          @include centerInline();
        }
      }
    }

    .stats-info__btns {
      margin-top: 1.5rem;
    }
  }
}

//_____________MEDIA QUERY__________

@media (max-width: 2800.98px) {
  :root {
    font-size: 26px;
  }
}

@media (max-width: 2600.98px) {
  :root {
    font-size: 24px;
  }
}

@media (max-width: 2300.98px) {
  :root {
    font-size: 22px;
  }
}

@media (max-width: 2100.98px) {
  :root {
    font-size: 20px;
  }
}

@media (max-width: 1800.98px) {
  :root {
    font-size: 19px;
  }
}

@media (max-width: 1700.98px) {
  :root {
    font-size: 18px;
  }
}

@media (max-width: 1600.98px) {
  :root {
    font-size: 17px;
  }
}

@media (max-width: 1500.98px) {
  :root {
    font-size: 16px;
  }

  //   .study.sliced {
  //     padding-right: 25rem;

  //     .study-test__nav {
  //       width: calc(100% - 47rem);
  //     }
  //   }

  //   .study-test {
  //     &__main.sliced {
  //       width: calc(100% - 25rem);

  //       .study-test__nav {
  //         width: calc(100% - 27.5rem);
  //       }
  //     }

  //     &__nav {
  //       &.sliced {
  //         width: calc(100% - 48rem);
  //       }
  //     }

  //     &__side_wrapper {
  //       width: 0;
  //       overflow: hidden;
  //       transition: all 0.5s ease-in-out;
  //       position: relative;
  //     }
  //     &__side_wrapper_close {
  //       width: 0;
  //       overflow: hidden;
  //       transition: all 0.5s ease-in-out;
  //       position: relative;
  //     }

  //     &__side {
  //       width: 26.5rem;
  //       .header-search__input input {
  //         width: 16.5rem;
  //       }
  //     }
  //   }
}

@media (max-width: 1400.98px) {
  :root {
    font-size: 15px;
  }
}

@media (max-width: 1300.98px) {
  :root {
    font-size: 14px;
  }
}

@media (max-width: 1200.98px) {
  :root {
    font-size: 13px;
  }
}

@media (max-width: 1100.98px) {
  :root {
    font-size: 12px;
  }
}

@media (max-width: 1000.98px) {
  :root {
    font-size: 11px;
  }
}

@media (max-width: 900.98px) {
  :root {
    font-size: 10px;
  }
}

@media (max-width: 1080px) {
  :root {
    font-size: 17px;
  }

  .login {
    &-reg {
      .login__logo {
        position: absolute;
      }
    }
  }

  .side {
    display: none;
  }

  .content {
    height: auto;
    padding-bottom: 5rem;
  }

  .header {
    &__logo {
      display: flex;
      width: 7rem;
      color: $dark;
      flex-shrink: 0;

      &:hover {
        color: $dark;
      }

      svg {
        width: 100%;
      }
    }

    &-search {
      &__result {
        width: 70vw;
      }

      &__input {
        svg {
          right: 0.75rem;
          left: auto;
        }

        input {
          width: 60vw;
          padding-left: 1rem;
          padding-right: 2.5rem;
        }
      }
    }

    &__side,
    &-lang,
    &__nots,
    &__user {
      display: none;
    }

    &-mobile {
      display: flex;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .mobile-library {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 5.5rem;
    background: #fff;
    z-index: 5;
    @include spaced();
    padding: 0 1.5rem;
    border-radius: 0 0 1.1rem 1.1rem;

    &__wrap {
      display: flex;
      align-items: center;
      gap: 0.9rem;
    }

    &__btn {
      @include centerInline();
      width: 1.5rem;
      height: 1.5rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__title {
      font-size: 1.1rem;
    }
  }

  .library {
    &-list {
      li {
        width: calc(33.3333% - 1rem);
      }
    }

    &-head {
      &__wrap {
        display: none;
      }
    }

    &-setting {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 7;
      background: rgba($color: #000, $alpha: 0.6);

      &__content {
        bottom: 0;
        left: 0;
        width: 100%;
        position: absolute;
        border-radius: 0.6rem 0.6rem 0 0;
        overflow: hidden;
        background: #fff;
      }

      &__title {
        padding: 0.5rem;
        @include spaced();
        font-weight: 600;
        background: $lightBorder;
      }

      &__wrap {
        padding: 0.7rem 0.5rem;
        border-bottom: 1px solid $lightBorder;
      }

      &__button {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        margin-bottom: -0.8rem;

        &:last-child {
          margin-bottom: 0;
        }

        svg {
          width: 1.4rem;
          height: 1.4rem;
          color: $grey;
        }

        .checkbox {
          width: 1.4em;
          height: 1.4rem;
          border-radius: 50%;
          border-color: $grey;
        }
      }

      &__more {
        padding: 0.5rem 0.5rem 1rem;
      }

      &__name {
        font-weight: 600;
        margin-bottom: 0.8rem;
      }

      &__item {
        @include spaced();
        margin-bottom: 0.8rem;

        &-wrap {
          width: 100%;
        }

        .checkbox {
          width: 1.5rem;
          height: 1.5rem;
          margin-left: 5rem;
          flex-shrink: 0;
          border-color: $grey;
          border-radius: 50%;
        }

        &-title {
          margin-bottom: 0.25rem;
        }

        &-text {
          color: $grey;
          font-size: 0.9rem;
        }
      }
    }

    &-mobile__plan {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 7;
      background: rgba($color: #000, $alpha: 0.6);

      .side-plan {
        background: #fff;
        @include absoluteCenterY();
        right: 0;
        border-radius: 0.5rem;
        font-size: 1rem;
        width: 18rem;
        margin-right: 0;
        border: none;
        max-height: 90vh;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        &__item {
          padding-right: 1.5rem;
        }
      }

      .side-stat {
        padding-bottom: 1rem;
      }

      .side-time {
        position: static;
        margin-top: 3rem;

        &__left {
          justify-content: start;
        }

        &__time {
          width: calc(100% - 3rem);
        }
      }
    }

    &-gallery {
      &__content {
        flex-direction: row;
      }

      &__main {
        padding: 1.5rem 0 5rem;
        flex-direction: row;
        max-width: 100dvw;
        flex-wrap: wrap;
        justify-content: space-between;
        div {
          width: 100%;
          img,
          iframe {
            // width: 80%;
            height: auto;
          }
        }
      }

      &__close {
        order: 1;
        margin-left: 1.5rem;
      }

      &__btns {
        order: 2;
        margin-right: 1.5rem;
        height: 4rem;
      }

      &__img {
        order: 3;
        width: 100%;
      }

      &__info {
        width: auto;
      }
      .close {
        width: 0;
        min-width: 0;
        height: 100%;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
      }

      &__text {
        @include trans();
        width: 18rem;
        height: 100%;
        overflow: scroll;
        transition: all 0.5s ease-in-out;
        &__content {
          min-width: 18rem;
          height: 100%;
          overflow: hidden;
        }
        max-height: 18rem;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__slide {
        left: auto;
        bottom: auto;
        left: -1rem;
        width: 2.3rem;
        height: 3.3rem;
        bottom: 1.15rem;
        position: absolute;

        svg {
          //   transform: rotate(90deg);
        }
        &.closed {
          svg {
            transform: rotate(180deg) !important;
          }
        }
      }
    }
  }



  .study {
    width: 100%;
    &.sliced {
      padding-right: 0;

      //   .study-test__nav {
      //     width: calc(100% - 3rem);
      //   }
    }

    &-test {
      padding-bottom: 1rem;

      &__edited {
        height: calc(100vh - 7.5rem);
      }

      &__flex {
        display: flex;
        gap: 0.5rem;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        button {
          min-width: 20%;
          max-width: 30%;
          img {
            width: 100%;
          }
        }
      }

      //   &__nav {
      //     width: calc(100% - 3rem);

      //     &.sliced {
      //       width: calc(100% - 3rem);
      //     }
      //   }

      &__main.sliced {
        width: 100%;

        .study-test__nav {
          width: 100%;
          min-width: 100%;
        }
      }

      //   &__side_wrapper {
      //     width: 100%;
      //     top: 0;
      //     right: 0;
      //     bottom: 0;
      //     overflow: hidden;
      //     transition: all 0.5s ease-in-out;
      //     position: fixed;
      //     position: static;
      //   }
      //   &__side_wrapper_close {
      //     width: 0;
      //     top: 0;
      //     right: 0;
      //     bottom: 0;
      //     overflow: hidden;
      //     transition: all 0.5s ease-in-out;
      //     position: fixed;
      //     position: static;
      //   }

      //   &__side {
      //     transform: none;
      //     top: 20vh;
      //   }

      &__search .header-search__input input {
        width: 100%;
      }

      &__table {
        height: calc(100vh - 20rem);
      }
    }
  }

  .account {
    &-content {
      display: block;
    }

    &-side {
      width: 100%;
      position: static;
      margin-bottom: 1.5rem;
      margin-right: 0;
    }

    &-main {
      width: 100%;
    }
  }

  .help {
    &-content {
      display: block;
    }

    &-side {
      position: relative;
      top: 0;
      width: 100%;
      margin-right: 0;
      margin-bottom: 1.5rem;
    }

    &-main {
      width: 100%;
    }
  }

  .footer {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.6rem 1rem 1.2rem;
    border-radius: 1.5rem 1.5rem 0 0;
    background: #fff;
    z-index: 5;

    &-menu {
      display: flex;
      justify-content: space-around;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.3rem;
        font-size: 0.9rem;
        color: $grey;

        svg {
          width: 1.2rem;
          height: 1.2rem;
        }

        &.current {
          span {
            color: $green;
          }
          color: $green;
          position: relative;

          &::after {
            position: absolute;
            left: 0;
            height: 0.2rem;
            bottom: -0.5rem;
            width: 0%;
            animation: fillBorder 1s linear forwards;
            background: $gradGreen;
            content: '';
          }

          svg {
            color: $green;
          }
        }

        &.disabled {
          opacity: 0.2;
          pointer-events: none;
        }
      }
    }

    &-library {
      padding: 0.75rem 1rem;

      .footer-menu a {
        color: $dark;
      }
    }
  }
}

@media (max-width: 767.98px) {
  :root {
    font-size: 16px;
  }

  body.dark {
    .library-article {
      background: none;
    }

    .header {
      border-bottom: none;
      background: $darkBg;

      &__logo {
        color: #ddd;
      }

      &-search__input input {
        background: $darkBg;
      }
    }
  }

  .p404 {
    overflow: hidden;

    &__logo svg {
      width: 10rem;
      height: 3.5rem;
    }

    &__number {
      margin-bottom: 1rem;

      svg {
        width: 13rem;
      }
    }

    &__title {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }

    &__ico {
      margin-bottom: 3rem;
      overflow: hidden;
      text-align: center;
      display: flex;
      justify-content: center;

      svg {
        width: 150vw;
      }
    }
  }

  .header {
    padding: 0 1rem;
    height: 4rem;

    &-search {
      &__input input {
        width: 8rem;
      }

      &__result {
        width: 22rem;
        margin-left: -8rem;
      }
    }
  }

  .content {
    padding: 1.5rem 1rem 6rem;
  }

  .section-title {
    margin-bottom: 1.2rem;
  }

  .section-back {
    margin-bottom: 1rem;
  }

  .login {
    &__logo {
      top: 0.5rem;
      width: 7rem;
    }

    &-content {
      width: calc(100% - 2rem);
    }

    &-form {
      font-size: 1rem;
    }
  }

  .mobile-library {
    height: 4rem;
  }

  .library {
    &-list {
      gap: 1rem;

      li {
        width: calc(50% - 0.5rem);
      }

      &-article {
        li {
          width: 100%;
        }
      }
    }

    &-item {
      font-size: 0.9rem;
    }

    &-article {
      padding: 0;
      background: none;
      flex-direction: row;
      align-items: center;
      text-decoration: underline;
      height: auto;

      svg {
        width: 1rem;
        height: 1rem;
        flex-shrink: 0;
      }
    }

    &-accordion {
      &__head {
        padding: 1.2rem;
      }

      &__content {
        padding: 1.2rem;
      }
    }

    &-gallery {
      &__main {
        flex: 1;
        min-height: 100vh;
        max-height: 100vh;
        div {
          width: 100%;
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          img,
          iframe {
            max-height: 80%;
            // width: 100%;
          }
        }
      }

      &__close,
      &__edit {
        width: 2.5rem;
        height: 2.5rem;

        img {
          width: 1rem;
        }
      }

      &__img {
        height: 20rem;
      }

      &__close {
        margin-left: 1rem;
      }

      &__btns {
        margin-right: 1rem;
        height: 2.5rem;
        position: absolute;
        top: 1rem;
        right: 1rem;
      }

      &__zoom {
        height: 2.5rem;
        gap: 1rem;
        padding: 0 1rem;

        button {
          width: 1rem;
          height: 1rem;
        }
      }

      &__info {
        padding: 1rem;
        width: 100vw;
        position: absolute;
        max-height: 20rem;
        justify-content: flex-end;
        align-self: flex-end;
        .section-title {
          font-size: 1.2rem;
        }
        .library-gallery__slide {
          top: -1.5rem;
          left: auto;
          right: 2rem !important;
          position: absolute !important;
          transform: rotate(90deg);
        }
      }
      .close {
        width: 100vw !important;
        min-width: 100%;
        height: 0;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
      }

      &__text {
        @include trans();
        width: 100%;
        max-height: 20rem;
        height: 20rem;
        overflow: scroll;
        transition: all 0.5s ease-in-out;
        &__content {
          width: 100%;
          max-height: 20rem;
          overflow: hidden;
        }
      }
    }

    &-popup {
      .library-gallery__close {
        left: 0;
      }

      &__content {
        width: calc(100% - 2rem);
        padding: 1.5rem 1rem;
      }

      &__ico {
        margin-bottom: 1.5rem;

        svg {
          width: 6rem;
          height: 6rem;
        }
      }
    }
  }

  .study {
    &.sliced {
      //   .study-test__nav {
      //     width: calc(100% - 2rem);
      //   }

      //   .study-test__side {
      //     transform: translateY(-50%);
      //   }
    }

    &__title,
    &__text {
      margin-bottom: 1rem;
    }

    &-list {
      gap: 1rem;

      &__item {
        width: calc(50% - 0.5rem);
        padding: 2.5rem 0.5rem 1rem;
      }

      &__name {
        font-size: 0.9rem;
      }
    }

    &__subtitle {
      margin-top: -0.5rem;
    }

    &-choose {
      gap: 1rem;
    }

    &-item {
      width: 100%;

      &__head {
        min-height: 5rem;
        font-size: 0.9rem;
      }
    }

    &-themes {
      &__item {
        padding: 1.2rem 0.7rem;
      }

      &__wrap span {
        font-size: 0.9rem;
      }

      &__check {
        margin-left: 2rem;

        span span {
          display: none;
        }
      }
    }

    &-questions {
      &__item {
        padding: 1.2rem 0.7rem;
      }

      &__wrap {
        display: block;

        .study-item__info {
          margin-bottom: 1rem;
          width: 100%;
        }
      }

      &__name {
        font-size: 0.9rem;
        margin-bottom: 1rem;
      }
    }

    &-test {
      &.sliced {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
      &__text {
        padding: 1rem;
      }

      &__flex {
        flex-direction: column;
        button {
          min-width: 50%;
        }
        img {
          max-width: 100%;
        }
      }

      &__buttons {
        gap: 1rem;
        padding: 0 1rem 1rem;
        flex-direction: column;
      }

      &__advice {
        padding: 0.5rem 1rem 1rem;
      }

      &__wrap {
        gap: 1rem;

        button {
          font-size: 0.8rem;

          svg {
            width: 1rem;
            height: 1rem;
            margin-right: 0.4rem;
          }
        }
      }

      &__nav {
        width: 100%;

        &.sliced {
          //   width: calc(100% - 2rem);
        }

        .left svg {
          left: 0.5rem;
        }

        .right svg {
          right: 0.5rem;
        }
      }
      @keyframes example {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }
      @keyframes example-exit {
        from {
          width: 100%;
        }
        to {
          width: 0;
        }
      }

      &__side_wrapper {
        width: 100%;
        min-width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        position: fixed;
        z-index: 100;
        animation-name: example;
        animation-duration: 0.5s;
      }
      &__side_wrapper_close {
        width: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        position: fixed;
        animation-name: example-exit;
        animation-duration: 0.5s;
      }

      &__side {
        @include absoluteCenterY();
        bottom: auto;
        height: 90vh;
        width: 90vw;

        &-close {
          height: 2.5rem;
          font-size: 0.8rem;
        }
      }

      &__head li {
        height: 2.5rem;
        font-size: 0.8rem;
      }

      &__table {
        margin-bottom: 3rem;
        font-size: 0.8rem;
        height: 69vh;
        max-height: 70vh;
        overflow: scroll;

        &-row {
          div {
            padding: 0.4rem 1rem;
          }
        }
      }

      &__search {
        .header-search__input input {
          width: 12rem;
          padding-left: 2rem;
        }
      }
    }

    &-quest {
      font-size: 0.9rem;

      &__answer {
        padding: 1rem 1.5rem;
      }
    }
  }

  .news {
    &-slider {
      &__item {
        border-radius: 0.6rem;
      }

      &__category {
        font-size: 0.8rem;
      }

      &__title {
        font-size: 0.9rem;
      }

      &__desc {
        font-size: 0.8rem;
      }
    }

    &-item {
      border-radius: 0.6rem;
      display: block;
      height: auto;

      &__img {
        height: 13rem;
        width: 100%;

        img {
          border-radius: 0.6rem;
        }
      }

      &__wrap {
        padding: 0.6rem;
      }

      &__title {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }

      &__desc {
        font-size: 0.9rem;
      }

      &__bot {
        position: static;
        width: 100%;
      }
    }

    &-single {
      &__banner {
        margin-bottom: 1rem;

        p {
          margin-top: 0.3rem;
          font-size: 0.8rem;
        }
      }

      &__text {
        p {
          margin-bottom: 1rem;
          font-size: 0.9rem;
        }
      }

      &__quote {
        padding: 0.7rem;

        p {
          margin-bottom: 0;
          padding-left: 0.4rem;
          border-width: 0.2rem;
        }
      }
    }
    &-content {
      padding-bottom: 3rem;
    }
  }

  .account {
    &-head {
      overflow: scroll;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 0.5rem;
      justify-content: flex-start;
      max-width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        // flex-shrink: 0;
        width: 30vw;

        min-width: 30vw;
      }
    }

    &-settings {
      gap: 1rem;

      &__item {
        width: 100%;
      }

      &__info {
        margin-top: 0;
      }
    }

    &-popup {
      &__close {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 0.8rem;
        left: 1rem;

        img {
          width: 1rem;
        }
      }

      &__content {
        width: calc(100% - 2rem);
        padding: 2rem 1rem;

        .rate {
          &-card {
            padding: 0;
          }
        }
      }

      .btn {
        width: 100%;
      }
    }
  }

  .rate {
    &-table {
      max-width: 100%;
      overflow: auto;

      &__row div {
        width: calc(50vw - 2.6rem) !important;
        flex-shrink: 0;
        padding: 1rem;
      }
    }

    &-devices {
      font-size: 0.6rem;

      &__wrap {
        img {
          width: 1.4rem;
          margin-right: 0.4rem;
        }
      }

      &__btn {
        height: 1.8rem;
        width: 3.5rem;
      }
    }

    &-item {
      width: 100%;

      &__name {
        font-size: 1.3rem;
        margin-bottom: 1rem;
      }
    }

  }

  .message {
    width: calc(100vw - 4rem);
    padding: 1rem;
    font-size: 1rem;

    &__ico {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }

  .help {
    &-side {
      padding: 1rem;
    }

    &-main {
      &__title {
        font-size: 1rem;
      }

      &__text {
        font-size: 0.8rem;

        p {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .stats {
    &-head {
      padding: 1.2rem 1rem;
      margin-bottom: 1rem;

      &__value {
        font-size: 0.8rem;
      }

      &__name {
        font-size: 0.65rem;

        img {
          width: 0.65rem;
          margin-right: 0.15rem;
        }
      }
    }

    &-main {
      padding: 1.2rem;

      &__wrap {
        display: block;
      }
    }

    &-circle {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1.2rem;
    }

    &-info {
      width: 100%;
    }

    &-popup {
      &__content {
        width: 90%;
      }

      &__title {
        font-size: 1.3rem;
      }
    }
  }

  .footer-menu {
    justify-content: space-between;

    a {
      font-size: 0.9rem;
    }
  }
}

@media (max-width: 389.98px) {
  :root {
    font-size: 15px;
  }
}

@media (max-width: 375.98px) {
  :root {
    font-size: 14px;
  }
}

@media (max-width: 345.98px) {
  :root {
    font-size: 13px;
  }
}

@media (max-width: 330.98px) {
  :root {
    font-size: 12px;
  }
}

@media (max-width: 320.98px) {
  :root {
    font-size: 11px;
  }
}

@media (max-width: 300.98px) {
  :root {
    font-size: 10px;
  }
}

//___________MOBILE-LANDSCAPE________

@media (max-width: 1000px) and (orientation: landscape) {
  :root {
    font-size: 13px;
  }
  .login {
    .account {
      &-popup {
        &__text {
          position: relative;
          overflow-x: hidden;
          max-height: 45vh;
          overflow-y: scroll;
          text-align: left;
          &::-webkit-scrollbar {
            width: 0.5rem;
            height: 0.5rem;
          }

          &::-webkit-scrollbar-track {
            background: #ddd;
          }

          &::-webkit-scrollbar-thumb {
            background: $green;
          }
        }
      }
    }
  }

  .header {
    height: 4.5rem;
  }

  .mobile-menu {
    &__head {
      margin-bottom: 1.5rem;
    }

    .mobile-menu__list a {
      padding: 0.75rem;
      gap: 0.75rem;
    }

    .side-mode {
      bottom: 5.5rem;
    }
  }

  .main {
    width: calc(100% - 6rem);
  }
  .test {
    &__layout {
      width: 100%;
    }
    &__content {
      padding: 1.5rem 1rem 1rem;
    }
  }

  .footer {
    width: 6rem;
    height: 100%;
    left: auto;
    right: 0;
    border-radius: 0;
    border-left: 1px solid #ddd;
    text-align: center;

    &-menu {
      flex-direction: column;
      height: 100%;
    }
  }

  .library-gallery__content {
    flex-direction: row;
  }

  .library-gallery__slide {
    top: 5rem;
    left: -2rem;

    svg {
      transform: none;
    }

    &.closed svg {
      transform: rotate(180deg) !important;
    }
  }

  .mobile-library {
    height: 4.5rem;
    padding-right: 8rem;
  }

  .study-test__search .header-search__input input {
    padding-left: 2rem;
  }

  .study-test__side {
    transform: none;
    top: auto;
    bottom: 0;
  }

  //   .study-test__table {
  //     height: 10rem;
  //   }

  //   .study-test__nav {
  //     width: calc(100% - 10.5rem);
  //   }
}

@media (max-width: 700px) and (orientation: landscape) {
  :root {
    font-size: 12px;
  }

  //   .study-test__nav {
  //     width: calc(100% - 9.5rem);
  //   }
}
