// table {
//   border-collapse: collapse;
// }
/* Apply a default padding if legacy cellpadding attribute is missing */
// table:not([cellpadding]) th,
// table:not([cellpadding]) td {
//   padding: 0.4rem;
// }
/* Set default table styles if a table has a positive border attribute
   and no inline css */
// table[border]:not([border='0']):not([style*='border-width']) th,
// table[border]:not([border='0']):not([style*='border-width']) td {
//   border-width: 1px;
// }
/* Set default table styles if a table has a positive border attribute
   and no inline css */
// table[border]:not([border='0']):not([style*='border-style']) th,
// table[border]:not([border='0']):not([style*='border-style']) td {
//   border-style: solid;
// }
/* Set default table styles if a table has a positive border attribute
   and no inline css */
// table[border]:not([border='0']):not([style*='border-color']) th,
// table[border]:not([border='0']):not([style*='border-color']) td {
//   border-color: #ccc;
// }
figure {
  display: table;
  margin: 1rem auto;
}
figure figcaption {
  color: #999;
  display: block;
  margin-top: 0.25rem;
  text-align: center;
}
hr {
  border-color: #ccc;
  border-style: solid;
  border-width: 1px 0 0 0;
}
code {
  background-color: #e8e8e8;
  border-radius: 3px;
  padding: 0.1rem 0.2rem;
}
.mce-content-body:not([dir='rtl']) blockquote {
  border-left: 2px solid #ccc;
  margin-left: 1.5rem;
  padding-left: 1rem;
}
.mce-content-body[dir='rtl'] blockquote {
  border-right: 2px solid #ccc;
  margin-right: 1.5rem;
  padding-right: 1rem;
}

/* -------------------===================------------------------- */
$dark: #121b2d;
$darkBg: #1f2638;
$blue: #2b63d3;
$green: #4daf00;
$orange: #f79f44;
$grey: #a3b2bd;
$grey2: #ccc;
$lightBorder: #eef2f5;
$red: #ed2709;
$brown: #926522;
$yellow: #f9c206;

img,
legend {
  border: 0;
}
legend,
td,
th {
  padding: 0;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
optgroup,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre,
textarea {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}
input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

img {
  max-width: 100%;
  height: auto;
}
*,
::after,
::before {
  box-sizing: border-box;
}

.closed svg {
  transform: rotate(180deg);
}

#my-tooltip {
  opacity: 1;
  width: 500px !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-radius: 0.625rem;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.3125rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  color: #000;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  //   transition: opacity 0.5s ease-in-out !important;
}

.header-user,
section {
  animation: fade 0.5s ease-in-out;
}

img,
.library-gallery,
.account-popup,
.stats-popup,
.library-setting,
.library-setting,
.side-plan {
  animation: fade 0.5s ease-in-out;
}
.side {
  transition: all 0.5s ease-in-out;
}

.open-modal {
  display: block;
  max-width: 100dvh !important;
  min-width: 100dvh !important;
  animation: fade 0.5s ease-in-out;
  z-index: 100;
}

.close-modal {
  display: none;
  max-width: 100dvh;
  animation: fade 0.5s ease-in-out;
}

.fade-in {
  animation: fade 0.5s ease-in-out;
}

.fade-out {
  animation: fade-back 1s ease-in-out;
  transition: all 1s ease-in-out;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-back {
  from {
    opacity: 1;
    display: block;
  }
  to {
    opacity: 0;
    overflow: hidden;
    display: none;
  }
}
@keyframes fade-back {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#tinymce {
  transition: all 0.5s ease-in-out;
}
span {
  line-height: 120% !important;
  font-size: 1rem;
}
.dangerous {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  line-height: 120% !important;
  ul {
    display: block;
    // list-style-type: disc;
    // margin-block-start: 1em;
    // margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-left: 0.3rem;
    padding-inline-start: 1rem;
    // list-style-type: disc;
    margin-block-start: 0.5rem;
    margin-block-end: 1rem;
    text-indent: 0rem;
    li {
      display: list-item;
      text-align: -webkit-match-parent;
      margin-block-start: 0.1rem;
      margin-block-end: 0.2rem;
      ul {
        padding-inline-start: 0.7rem;
        text-indent: 0rem;
        margin-block-start: 0.1rem;
        margin-block-end: 0.2rem;
      }
    }
  }
  span {
    font-size: 1em;
  }
  ol {
    display: block;
    // list-style-type: disc;
    // margin-block-start: 1em;
    // margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 1rem;
    // list-style-type: disc;
    margin-block-start: 0.5rem;
    margin-block-end: 1rem;
    // text-indent: -0.5rem;
    li {
      display: list-item;
      text-align: -webkit-match-parent;
      ul {
        padding-inline-start: 0.7rem;
        text-indent: -0.3rem;
        margin-block-start: 0.1rem;
        margin-block-end: 0.2rem;
      }
    }
  }
  ol {
    display: block;
    // list-style-type: disc;
    // margin-block-start: 1em;
    // margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 1rem;
    // list-style-type: disc;
    margin-block-start: 0.5rem;
    margin-block-end: 1rem;
    // text-indent: -0.5rem;
    margin-left: 0.5rem;
    li {
      display: list-item;
      text-align: -webkit-match-parent;
      ol {
        padding-inline-start: 0.7rem;
        text-indent: -0.3rem;
        margin-block-start: 0.1rem;
        margin-block-end: 0.2rem;
      }
    }
  }
  span {
    line-height: 120% !important;
  }
  table {
    overflow: scroll;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  a {
    cursor: pointer;
    &:hover {
      color: #3b6fd7;
      text-shadow: 0 0 0px #fff, 0 0 2px #fff, 0 0 5px #fff, 0 0 1px #3b6fd7, 0 0 1px #3b6fd7, 0 0 1px #3b6fd7,
        0 0 1px #3b6fd7, 0 0 1px #3b6fd7;
    }
  }

  #tableWrapper {
    max-width: 100% !important;
    overflow-x: scroll !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
    table {
      max-width: 100% !important;
      width: 100% !important;
      td {
        padding: 1rem;
      }
    }
    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background: #ddd;
    }

    &::-webkit-scrollbar-thumb {
      background: $green;
    }
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .image-icon {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    border: 2px solid #000;
    object-fit: cover;
  }
  .image-icon-small {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    border: 1px solid #000;
  }

  //   ul,
  //   ol {
  //     all: initial;
  //     color: #121b2d;
  //   }
}
.MsoNormal {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.dark {
  #tableWrapper {
    overflow-x: auto !important;
    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background: #ddd;
    }

    &::-webkit-scrollbar-thumb {
      background: $green;
    }
  }
  #my-tooltip {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.3125rem;
    flex-shrink: 0;
    color: #ddd;
    border-radius: 0.625rem;
    background: var(--Extra, #161718);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  }
  .dangerous {
    * {
      background-color: transparent !important;
    }
    span {
      color: #ddd !important;
    }
    line-height: 120%;
    li:has(> u) {
      display: none !important;
      /* styles to apply to the li tag */
    }
    &::-webkit-scrollbar {
      display: none;
    }
    color: #ddd;
    // span {
    //   color: #fff;
    //   background-color: #000 !important;
    // }
    a {
      cursor: pointer;
      color: #f7a542;
      &:hover {
        text-shadow: 0 0 0px #fff, 0 0 2px #fff, 0 0 5px #fff, 0 0 1px #f7a542, 0 0 1px #f7a542, 0 0 1px #f7a542,
          0 0 1px #f7a542, 0 0 1px #f7a542;
      }
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    .image-icon {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      border: 2px solid #000;
      object-fit: cover;
    }
    .image-icon-small {
      width: 10px;
      height: 10px;
      border-radius: 2px;
      border: 1px solid #000;
    }
  }
}
@media screen and (max-width: 480px) {
  #my-tooltip {
    width: 90% !important;
  }
  .dangerous {
    ul {
      li {
        ul {
          padding-inline-start: 0.7rem;
          text-indent: 0rem;
          margin-block-start: 0.1rem;
          margin-block-end: 0.2rem;
        }
      }
    }
  }
}
.library-gallery__text {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.library-gallery__text::-webkit-scrollbar {
  display: none;
}

#my-tooltip::-webkit-scrollbar {
  display: none;
}

.header {
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.15);
  position: relative;
}

.active_action {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.side-mini,
.side {
  * {
    transition: all 0.1s ease-in-out;
  }
}

.tooltip-html {
  transition: all 1s ease-in-out;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.transform-to-left {
  animation: example 0.5s ease-in-out;
}

@keyframes example {
  from {
    transform: translate(100%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

.disable-test-stats {
  background: #a3b2bd !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
