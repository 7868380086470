.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}
.page {
  border: #67ac44 1px solid;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.pageLink {
  color: #1c1a1a;
  cursor: pointer;
}
.active {
  background: linear-gradient(180deg, #84bc3f 0%, #4fa446 100%);

  border-radius: 0.5rem;
  padding: 0.5rem;
}
.activeLink {
  color: #f4efef;
  cursor: pointer;
}
.previous {
  cursor: pointer;
}
.next {
  cursor: pointer;
}
